import React, { useState, useEffect } from 'react'
import {NavLink} from "react-router-dom"
import { useTranslation } from 'react-i18next'
import {ButtonPrimary} from "../general/Button"
import {LoadingOverlay} from "../general/Loading"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {routes as Routing} from "../../services/RoutesHelper"
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import logs from './changelog.md'
import './changelog.css'

export default function ChangelogPage(){

    const { t } = useTranslation()
    const [markdown, setMarkdown] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    useEffect( () => {
        fetch( logs )
            .then( response => response.text() )
            .then ( data => setMarkdown(data) )
            .finally( () => setIsLoading(false) )
    }, [] )

    return <>
            {isLoading
                ? <LoadingOverlay/>
                : <>
                    <NavLink to={Routing.game.path}>
                        <ButtonPrimary type="button" name="loginButton">
                            <FontAwesomeIcon icon="fa-solid fa-arrow-left"/> {t("game.changelog.back")}
                        </ButtonPrimary>
                    </NavLink>
                    <div className="changelog-content">
                        <h1>{t("game.changelog.title")}</h1>
                        <div className='wrap-markdown'>
                            <div className='markdown-body'>
                                <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
}