export const routes = {
    app_home: {path: "/", title: "route.home"},
    app_login: {path: "/security/login", title: "route.login"},
    app_logout: {path: "/logout", title: "route.logout"},
    app_activate: {path: "/security/activate", title: "route.activate"},
    game: {path: "/game", title: "route.game"},
    profile: {path: "/game/profile", title: "route.profile"},
    information_center: {path: "/game/information_center", title: "route.information"},
    parameters: {path: "/game/parameters", title: "route.parameters"},
    history: {path: "/game/history", title: "route.history"},
    quiz: {path: "/game/quiz", title: "route.quiz"},
    quiz_correction: {path: "/game/quiz/:id/correction", title: "route.quizCorrection"},
    quiz_correction_details: {path: "/game/quiz/:id/correction-details", title: "route.quizCorrectionDetails"},
    quiz_progress: {path: "/game/quiz/progress", title: "route.quiz.progress"},
    quiz_info: {path: "/game/quiz/info", title: "route.quiz.info"},
    challenge: {path: "/game/challenge", title: "route.challenge"},
    reward: {path: "/game/reward", title: "route.reward"},
    badge: {path: "/game/badge", title: "route.badge"},
    scoreboard: {path: "/game/scoreboard", title: "route.scoreboard"},
    changelog : {path: "/game/changelog", title: "route.changelog"}
}

/**
 * Return a route string url with parameters
 *
 * @param route {string} - the original route with param
 * @param params {object}
 * @return {string} - the formatted route
 */
export function setRouteParameters(route, params) {
    for (const [key, value] of Object.entries(params)) {
        route = route.replace(`:${key}`, value)
    }
    return route
}

/**
 *
 * @param path
 * @return {boolean}
 */
export function isGameRoute(path) {
    return path.startsWith("/game") || path.startsWith("/security")
}
